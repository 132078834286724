










































































import { Component, Prop } from 'vue-property-decorator';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import Notification from '@/models/graphql/Notification';
import EntityType from '@/utils/enums/EntityType';
import Exhibitor from '@/models/graphql/Exhibitor';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import CommunityUser from '@/models/graphql/CommunityUser';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const companyUserRoleStore = namespace('CompanyUserRoleStore');
const notificationStore = namespace('NotificationStore');
const toastStore = namespace('ToastStore');

@Component({
  computed: {
    FileResourceHelper() {
      return FileResourceHelper;
    },
  },
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    ButtonComponent,
  },
})

export default class CompanyInvitationPage extends BreakpointWrapper {
  @Getter
  protected readonly community!: Community;

  @Getter
  private authUser!: CommunityUser;

  @Prop({
    required: false,
    default: null,
  })
  private userCompanyInvitation!: Notification | null | undefined;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string; state: CompanyUserRoleStatusType;
  }) => Promise<CompanyUserRole>;

  @notificationStore.Action
  private setInteractedUser!: (uid: string) => void;

  private get exhibitor(): Exhibitor | null {
    if (this.userCompanyInvitation
      && this.userCompanyInvitation.initiator
      // eslint-disable-next-line no-underscore-dangle
      && this.userCompanyInvitation.initiator.__typename === EntityType.EXHIBITOR) {
      return Exhibitor.hydrate(this.userCompanyInvitation.initiator);
    }
    return null;
  }

  private onAccept(): void {
    if (this.userCompanyInvitation) {
      this.onInteractedWith(this.userCompanyInvitation.uid);
      this.$set(this.userCompanyInvitation.triggered as CompanyUserRole, 'state', CompanyUserRoleStatusType.ACCEPTED);
      this.updateCompanyUserRoleState({
        uid: this.userCompanyInvitation.triggered?.uid as string,
        state: CompanyUserRoleStatusType.ACCEPTED,
      })
        .then((companyRole) => {
          if (companyRole) {
            const exists = this.authUser.companyRoles?.filter((c) => c.uid === companyRole.uid);
            if (this.authUser && this.authUser.companyRoles && !exists) {
              this.authUser.companyRoles.push(companyRole);
            }
          }

          this.addNewAction(
            { type: ToastActionType.USER_ACCEPT_INVITATION },
          );
        })
        .catch(() => {
          this.addNewAction(
            { type: ToastActionType.USER_ACCEPT_INVITATION_ERROR },
          );
        });
    }
  }

  private onDecline(): void {
    if (this.userCompanyInvitation) {
      this.onInteractedWith(this.userCompanyInvitation.uid);
      // eslint-disable-next-line no-underscore-dangle
      if (this.userCompanyInvitation
        && this.userCompanyInvitation.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.userCompanyInvitation.initiator.__typename === EntityType.EXHIBITOR
        && this.userCompanyInvitation.triggered
        // eslint-disable-next-line no-underscore-dangle
        && this.userCompanyInvitation.triggered.__typename === EntityType.COMPANY_USER_ROLE) {
        this.$emit('on-decline');
        this.updateCompanyUserRoleState({
          uid: this.userCompanyInvitation.triggered?.uid as string,
          state: CompanyUserRoleStatusType.DECLINED,
        })
          .catch(() => {
            this.addNewAction(
              { type: ToastActionType.USER_DECLINE_INVITATION_ERROR },
            );
          });
      }
    }
  }

  private onInteractedWith(uid: string): void {
    this.setInteractedUser(uid);
  }
}
