var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container d-flex flex-column justify-content-center align-items-center my-80",staticStyle:{"min-height":"46vh"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('div',{class:[
            'mb-4',
            {'exhibitor-logo': _vm.isDesktop},
            {'exhibitor-logo-sm': !_vm.isDesktop}
          ]},[(_vm.community && _vm.community.logoFileResource)?_c('img',{staticClass:"w-100 h-auto",attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.community.logoFileResource, 'w160'),"alt":"company logo"},on:{"error":function (e) { _vm.community.logoFileResource = null }}}):_vm._e()]),(_vm.community)?_c('h1',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$tc('first-company-invitation.title', 0, { eventName: _vm.community.shortName }))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center align-items-center mb-2"},[_c('avatar-solo-widget',{staticClass:"mr-2",attrs:{"size":!_vm.isMobile ? 40 : 24,"src":_vm.FileResourceHelper.getFullPath(_vm.exhibitor.logoFileResource, 'w40'),"with-borders":true,"default-icon":"fa-regular fa-buildings","icon-classes":"icon-24 text-neutral-n-6-label"}}),_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.$tc('first-company-invitation.invited-you', 0, { companyName: _vm.exhibitor.name })),expression:"$tc('first-company-invitation.invited-you', 0, { companyName: exhibitor.name })"}],class:[
              'm-0 paragraph',
              {'large': !_vm.isMobile}
            ]})],1),_c('div',{class:[
            'd-flex column-gap-4',
            {'invitation-buttons': !_vm.isMobile},
            {'invitation-buttons-sm': _vm.isMobile}
          ]},[_c('button',{class:['paragraph paragraph-2 font-weight-bold text-blue-b-3-primary-blue border-0',
                     'text-uppercase bg-transparent p-0'],on:{"click":_vm.onAccept}},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.company.accept'))+" ")]),_c('button',{class:['paragraph paragraph-2 font-weight-bold text-neutral-n-6-label border-0',
                     'text-uppercase bg-transparent p-0'],on:{"click":_vm.onDecline}},[_vm._v(" "+_vm._s(_vm.$t('my-notification-list-component.company.decline'))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }