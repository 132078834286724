const components: Record<string, string> = {
  ActionButtonGroupWidget: 'action-buttons/ActionButtonGroupComponent.vue',
  ActionListWidget: 'action-list/ActionListWidget.vue',
  AdvertiseWidget: 'AdvertiseWidget.vue',
  AppDownload: 'AppDownloadWidget.vue',
  BigNumberWidget: 'cards/BigNumberComponent.vue',
  BigNumbersGroupWidget: 'BigNumbersGroupComponent.vue',
  BreadcrumbWidget: 'BreadcrumbWidget.vue',
  CarouselFeaturedWidget: 'CarouselComponent.vue',
  CartWidget: 'CartWidget.vue',
  CertificateBadgeWidget: 'CertificateBadgeComponent.vue',
  ChatWidget: 'ChatComponent.vue',
  CheckoutWidget: 'checkout/CheckoutWidget.vue',
  CmsAgendaWidget: 'full-calendar/AgendaWidget.vue',
  CmsHeaderWidget: 'company-cms/CmsPageHeaderWidget.vue',
  CmsMeetingsRequestWidget: 'company-cms/CmsCompanyMeetingWidget.vue',
  CmsTableWidget: 'CmsTableWidget.vue',
  CompanyCmsPageHeader: 'company-cms/CompanyCmsPageHeaderWidget.vue',
  CompanyCmsProfileAddressWidget: 'company-cms/CompanyCmsProfileAddressWidget.vue',
  CompanyCmsProfileCategoryWidget: 'company-cms/CompanyCmsProfileCategoryWidget.vue',
  CompanyCmsProfileContactWidget: 'company-cms/CompanyCmsProfileContactWidget.vue',
  CompanyCmsProfileHeaderWidget: 'company-cms/CompanyCmsProfileHeaderWidget.vue',
  CompanyCmsProfileInformationWidget: 'company-cms/CompanyCmsProfileInformationWidget.vue',
  CompanyCmsProfileOtherContactWidget: 'company-cms/CompanyCmsProfileOtherContactWidget.vue',
  CompanyCmsWebsiteList: 'company-cms/CompanyCmsWebsiteListWidget.vue',
  CompanyContactListWidget: 'CompanyContactListWidget.vue',
  CompanyContactWidget: 'CompanyContactComponent.vue',
  CompanyEventsWidget: 'CompanyEventsComponent.vue',
  CompanyLogoWidget: 'CompanyLogoWidget.vue',
  CompanyWebsiteList: 'company/CompanyWebsiteListWidget.vue',
  ContactSupportWidget: 'ContactSupportComponent.vue',
  CountdownWidget: 'CountdownWidget.vue',
  CreateAccountWidget: 'auth/CreateAccountPage.vue',
  DescriptionFormWidget: 'DescriptionFormWidget.vue',
  DetailPageHeaderWidget: 'page-header/DetailPageHeaderComponent.vue',
  DynamicTextWidget: 'DynamicTextComponent.vue',
  DynamicTileListWidget: 'DynamicTileListWidget.vue',
  DynamicTitleWidget: 'DynamicTitleComponent.vue',
  EntityInfoWidget: 'entity-info/EntityInfoComponent.vue',
  EntityListWidget: 'EntityListComponent.vue',
  EntitySearchWidget: 'entity-search/EntitySearchWidget.vue',
  EntityTagsWidget: 'EntityTagsWidget.vue',
  ExhibitorPortalDashboardAgendaWidget: 'exhibitor-portal/dashboard/agenda/EpAgendaDashboardWidget.vue',
  ExhibitorPortalDashboardLayoutWidget: 'exhibitor-portal/dashboard/EpDashboardLayoutWidget.vue',
  ExhibitorPortalDashboardMeetingRequestsWidget: 'exhibitor-portal/dashboard/meeting-request/EpMeetingRequestDashboardWidget.vue',
  ExhibitorPortalDashboardMessageWidget: 'exhibitor-portal/dashboard/EpMessageDashboardWidget.vue',
  ExhibitorPortalHeaderWidget: 'exhibitor-portal/ExhibitorPortalHeaderWidget.vue',
  ExhibitorPortalMessageWidget: 'exhibitor-portal/ExhibitorPortalMessageWidget.vue',
  ExhibitorPortalUserDetailWidget: 'exhibitor-portal/ExhibitorPortalUserDetailWidget.vue',
  ExhibitorPortalUserHeaderWidget: 'exhibitor-portal/ExhibitorPortalUserHeaderWidget.vue',
  ExtraPropertySectionWidget: 'extraPropertiesSection/ExtraPropertySectionWidget.vue',
  FaqHeaderWidget: 'FaqHeaderComponent.vue',
  FaqListWidget: 'FaqListWidget.vue',
  FeaturedCompanyWidget: 'FeaturedCompanyWidget.vue',
  FeedPostDetail: 'feed/FeedItemWrapperWidget.vue',
  FeedWidget: 'feed/FeedWidget.vue',
  FinancingCalculatorWidget: 'FinancingCalculatorWidget.vue',
  GenericPageHeaderWidget: 'page-header/GenericPageHeaderComponent.vue',
  GenericPillList: 'pill/GenericPillListComponent.vue',
  GenericSubPageHeaderWidget: 'page-header/GenericSubPageHeaderComponent.vue',
  ImageTileListWidget: 'ImageTilesComponent.vue',
  InformationCardListWidget: 'InformationCardListWidget.vue',
  JoinNetworkWidget: 'JoinNetworkWidget.vue',
  LivePollsPresentationWidget: 'live-session/LivePollPresentationWidget.vue',
  LiveQnAPresentationWidget: 'live-session/LiveQnAPresentationWidget.vue',
  LogoListWidget: 'LogoListWidget.vue',
  MapWidget: 'map/FloorplanComponent.vue',
  MediaBannerWidget: 'MediaBannerWidget.vue',
  MemberAboutWidget: 'MemberAboutComponent.vue',
  MembersWidget: 'MembersWidget.vue',
  MicropollNomadWidget: 'MicroPollWidget.vue',
  MyProfileAboutFormWidget: 'my-profile/MyProfileAboutFormComponent.vue',
  MyProfileContactsInfoWidget: 'MyProfileContactsInfoWidget.vue',
  MyProfileInterestsWidget: 'MyProfileInterestsWidget.vue',
  MyProfileRolesWidget: 'MyProfileRolesWidget.vue',
  NavigationBannerWidget: 'NavigationBannerWidget.vue',
  NextEventWidget: 'NextEventWidget.vue',
  OnBoardingStepWidget: 'onBoarding/UiOnBoardingStepWidget.vue',
  OrderCompletedWidget: 'checkout/OrderCompletedWidget.vue',
  OrderDetailWidget: 'OrderDetailWidget.vue',
  OrderListWidget: 'OrderListWidget.vue',
  PackageDetailWidget: 'PackageDetailWidget.vue',
  PageHeader: 'PageHeaderWidget.vue',
  PanelAside: 'PanelAsideComponent.vue',
  PanelCenter: 'PanelCenterComponent.vue',
  PanelContainer: 'PanelContainerComponent.vue',
  PanelWide: 'PanelWideComponent.vue',
  PillListWidget: 'pill/PillListComponent.vue',
  PollsWidget: 'PollsWidget.vue',
  PresenterWidget: 'PresenterComponent.vue',
  QnaWidget: 'QnaWidget.vue',
  ResourcesFormWidget: 'my-speaker-profile/ResourcesFormWidget.vue',
  ScheduleOfEventWidget: 'schedule-of-events/ScheduleOfEventWidget.vue',
  SearchResultWidget: 'search-result/SearchResultComponent.vue',
  Section: 'SectionComponent.vue',
  SessionCmsBoardWidget: 'speaker-cms/SessionCmsBoardWidget.vue',
  SessionCmsCoverWidget: 'session-cms/SessionCmsCoverWidget.vue',
  SessionCmsPageHeader: 'session-cms/SessionCmsPageHeaderComponent.vue',
  SessionLivePollsWidget: 'speaker-cms/SessionLivePollsWidget.vue',
  SessionLiveQnaWidget: 'speaker-cms/SessionLiveQnaWidget.vue',
  SessionLiveStreamConfigWidget: 'session-cms/SessionLiveStreamConfigComponent.vue',
  SessionTimelineWidget: 'SessionTimelineComponent.vue',
  SpeakerCmsHeaderWidget: 'speaker-cms/SpeakerCmsHeaderWidget.vue',
  SpeakerProfileAvatar: 'speaker-cms/SpeakerProfileAvatarWidget.vue',
  SpeakerProfileInfo: 'speaker-cms/SpeakerProfileInfoWidget.vue',
  SpeakerSocialLinksWidget: 'SocialLinksComponent.vue',
  SpeakersListWidget: 'SpeakersListComponent.vue',
  SponsorListWidget: 'SponsorListComponent.vue',
  StaticBannerWidget: 'StaticBannerWidget.vue',
  StaticHtmlWidget: 'StaticHtmlWidget.vue',
  StaticTitleWidget: 'StaticTitleWidget.vue',
  SurveyWidget: 'SurveyWidget.vue',
  TabItemListWidget: 'TabItemListWidget.vue',
  TabItemWidget: 'TabItemWidget.vue',
  TabMenuWidget: 'TabMenuComponent.vue',
  UiOnBoardingWidget: 'onBoarding/UiOnBoardingWidget.vue',
  UserPrivacyGuardWidget: 'UserPrivacyGuardWidget.vue',
  VideoEmbedWidget: 'VideoEmbedWidget.vue',
  WideCarouselWidget: 'WideCarouselComponent.vue',
  WizardLegalValidation: 'wizard/WizardLegalValidationWidget.vue',
  WizardStepWidget: 'wizard/WizardStepWidget.vue',
  WizardWidget: 'wizard/WizardWidget.vue',
};
export default components;
