import UiPage from '@/models/graphql/UiPage';
import EntityType from '@/utils/enums/EntityType';
import Session from '@/models/graphql/Session';
import GraphqlQueryHelper from '@/utils/helpers/GraphqlQueryHelper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { RawLocation, Route } from 'vue-router';
import { Vue } from 'vue-property-decorator';

export default class GenericPageGuard {
  static beforeLeave(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
  ): void {
    if (window.broadstreet) {
      window.broadstreet.clearCampaignLog();
    }
    next();
  }

  static beforeEnter(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => void) | void) => void,
  ): void {
    next((vm) => {
      // This mutation should be always there to init the selected tab on each navigation
      vm.$store.commit('setSessionSelectedTab', null);

      if (to.meta && 'childTab' in to.meta && !to.meta.childTab) {
        vm.$store.commit('PageStateManagementStore/initPageStates');
      }
      if (((vm as unknown) as { page: UiPage }).page) {
        const {
          id,
          existenceGraphql,
          entityType,
          paramName,
          permissionGraphql,
        } = ((vm as unknown) as { page: UiPage }).page;
        if (entityType && entityType.name === EntityType.SESSION && paramName) {
          vm.$store.dispatch('SessionStore/loadLiveSession', {
            uid: vm.$route.params[paramName] || paramName,
          })
            .then((session) => {
              ((vm as unknown) as { session: Session | undefined }).session = session;
            });
        }
        if (existenceGraphql && existenceGraphql !== '') {
          const existenceQuery = GraphqlQueryHelper.parseQueryElements(
            existenceGraphql, vm.$route.params,
            vm.$store.state.authUser
              ? vm.$store.state.authUser.uid
              : '',
            vm.$store.state.community && vm.$store.state.community.code
              ? vm.$store.state.community.code
              : '',
          );
          vm.$store.dispatch('WidgetDispatcherStore/runGqlQuery', {
            operationName: `CheckPageExist${id}`,
            query: existenceQuery,
          })
            .then((response: object) => {
              const result = Object.entries(response)[0][1] as [];
              if (!(result && result.length > 0)) {
                ((vm as unknown) as { notFoundPage: boolean }).notFoundPage = true;
              }
            });
        }
        if (permissionGraphql && permissionGraphql !== '') {
          const permissionQuery = GraphqlQueryHelper.parseQueryElements(
            permissionGraphql,
            vm.$route.params,
            vm.$store.state.authUser
              ? vm.$store.state.authUser.uid
              : '',
            vm.$store.state.community && vm.$store.state.community.code
              ? vm.$store.state.community.code
              : '',
          );
          vm.$store.dispatch('WidgetDispatcherStore/runGqlQuery', {
            operationName: `CheckPagePermissionFor${id}`,
            query: permissionQuery,
          })
            .then((response: object) => {
              const result = Object.entries(response)[0][1] as [];
              if (!(result && result.length > 0)) {
                ((vm as unknown) as { notAccessible: boolean }).notAccessible = true;
              }
            });
        }
        if (to.name === 'speaker-profile'
          && !(vm.$store.getters.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE)
            && vm.$store.getters.featureByKey(FeatureKeys.COMMUNITY_SPEAKER_HUB_FEATURE).enabled)) {
          ((vm as unknown) as { notAccessible: boolean }).notAccessible = true;
        }

        if (to.name === 'sign-up'
          && !(vm.$store.getters.featureByKey(FeatureKeys.USER_SIGNUP)
            && vm.$store.getters.featureByKey(FeatureKeys.USER_SIGNUP).enabled)) {
          ((vm as unknown) as { notAccessible: boolean }).notAccessible = true;
        }

        const isLoginPathHasRedirection = to.name === 'sign-in'
          && from.name !== null
          && from.name !== 'reset-password'
          && from.name !== 'enter-information'
          && from.name !== 'enter-password'
          && from.name !== 'enter-email'
          && from.name !== 'sign-in'
          && from.name !== 'create-password';
        if (!vm.$store.state.authUser && isLoginPathHasRedirection) {
          const redirect = encodeURIComponent(vm.$router.resolve({
            params: from.params,
            path: from.path,
            query: from.query,
          }).href);
          vm.$router.replace(vm.$router.resolve({
            params: to.params,
            path: to.path,
            query: {
              ...to.query,
              redirect,
            },
          }).location);
        }
        const isLoggedOutPage = to.name === 'reset-password';
        if (!!vm.$store.state.authUser && isLoggedOutPage) {
          vm.$store.dispatch('AuthenticationStore/logout');
        }
      }
    });
  }
}
